<template>
  <footer>
    <div class="container">
      <div class="left">
        <router-link :to="{name : 'Page_250_'+userLanguage}" class="nav-item">
          {{contents.footer.menuItem2}}
        </router-link>
        <router-link :to="{name : 'Page_249_'+userLanguage}" class="nav-item">
          {{contents.footer.menuItem1}}
        </router-link>
      </div>
      <div class="right ms-auto">
        <span>{{contents.footer.copyright}}</span>
        <router-link to=""><img src="../assets/img/MS_logo_white.svg" alt="Morgan Stanley"/> </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default{
  name:'Footer',
  props:['globalData'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][247]
    return{
      contents
    }
  },
  computed:{
    userLanguage(){
      return this.globalData.init.language
    }
  }
}
</script>

<style scoped>
footer{
  background-color: #002B51;
  color: #FFFFFF;
  padding: 2.22rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}
footer .container{
  display: flex;
}
footer .nav-item{
  font-size: 1.11rem;
  margin-right: 1.78rem;
}
footer span{
  font-size: .89rem;
  opacity: .5;
  margin-right: 5rem;
  margin-left: 1.5rem;
  white-space: nowrap;
}
footer a{
  white-space: nowrap;
}
footer img{
  width: 222px;
  height: 25px;
}
@media (max-width: 992px){
  footer .container{
    display: block;
  }
  .left{
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  .right{
    text-align: center;
    margin-left: 0;
  }
  footer span{
    margin: 0 0 1rem 0;
    display: block;
  }
}
</style>