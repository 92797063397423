<template>
  <Head :globalData="globalData"></Head>
  <Header :globalData="globalData"/>
  <Loader :globalData="globalData"/>
  <div class="alert-deck">
    <Alerts :alerts="alert" v-for="alert in globalData.alerts"/>
  </div>
  <div class="main-container">
    <SubPageTitle v-if="pageId!==247" :globalData="globalData"></SubPageTitle>
    <router-view :globalData="globalData"/>
  </div>
  <Footer :globalData="globalData"></Footer>
</template>

<script>
import { reactive} from 'vue'
import Loader from "./components/Loader"
import Head from './components/Head'
import Header from './components/Header'
import Footer from './components/Footer'
import Alerts from './components/Alerts'
import {CookieAgree} from './classes/cookieAgree.js'
import SubPageTitle from "@/components/SubPageTitle.vue";

export default {
  name: 'App',
  props: ['serverData'],
  components: {
    SubPageTitle,Loader,Head,Header,Footer,Alerts
  },
  data(){
    const globalData = reactive(this.serverData)
    console.log(this.serverData)
    globalData.loader.status = false

    //new CookieAgree()

    return {
      globalData
    }
  },
  computed:{
    pageId(){
      let matched = this.$route.matched[0]
      if(matched===undefined){
        return 249
      }else{
        return matched.props.default.pageId
      }

    }
  }
}
</script>

<style scoped>
.main-container{
  padding-top: 3rem;
  padding-bottom: 6rem;
}
@media (max-width: 992px){
  .main-container{
    padding-bottom: 14rem;
  }
}
</style>
