<template v-if="contents">
  <div class="title-holder">
    <div class="container">
      <h1 v-if="contents.hasOwnProperty('page')" v-html="contents.page.title"></h1>
    </div>
  </div>
</template>

<script>

export default{
  name:'SubPageTitle',
  props:['globalData'],
  data(){
    return{
    }
  },
  computed: {
    pageId(){
      return this.globalData.init.head.pageId
    },
    userLanguage() {
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      if(this.pageId===undefined){
        return false
      }
      return this.globalData.contents[language][this.pageId]
    }
  }
}
</script>

<style scoped>
.title-holder{
  background: url('@/assets/img/subpage_header.jpg') center center no-repeat;
  background-size: cover;
  margin-bottom: 5rem;
  height: 160px;
  white-space: nowrap;
}
.title-holder .container{
  position: relative;
  height: inherit;
}
.title-holder h1{
  font-size: 54px;
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
@media (max-width: 992px){
  .title-holder{
    white-space: normal;
  }
  .title-holder h1{
    font-size: 2rem;
  }
}
</style>