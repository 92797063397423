<template>
  <header class="header">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container d-flex align-items-center">
           <router-link class="navbar-brand" :to="{name : 'Page_247_'+userLanguage}">
              <img src="../assets/img/MS_logo_black.svg" alt="Morgan Stanley logo">
            </router-link>

            <div class="navbar-collapse collapse" id="headerNavbar">
                <ul class="navbar-nav ms-auto d-flex align-items-center">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{name : 'Page_251_'+userLanguage}">
                      {{contents.header.menuItem1}}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{name : 'Page_252_'+userLanguage}">
                      {{contents.header.menuItem2}}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{name : 'Page_253_'+userLanguage}">
                      {{contents.header.menuItem3}}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{name : 'Page_254_'+userLanguage}">
                      {{contents.header.menuItem4}}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <a :href="contents.header.menuItem6Href" target="_blank" rel="noreferrer" class="btn btn-secondary btn-registration">{{contents.header.menuItem6}}</a>
                  </li>
                  <li class="nav-item dropdown login-dropdown">
                    <a class="nav-link dropdown-toggle btn btn-secondary btn-registration" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-haspopup="true" aria-expanded="false">{{contents.header.menuItem7}}</a>
                    <div class="dropdown-menu dropdown-menu-end">
                      <form method="POST" class="login-form text-center" @submit.prevent="loginHandler">
                        <div class="form-group mb-3">
                          <input name="email" v-model="email" class="form-control" :class="{ 'is-invalid': emailError }"  type="text" :placeholder="contents.header.login.placeHolder1" autofocus/>
                          <div v-if="emailError" class="invalid-feedback">
                            {{ emailError }}
                          </div>
                        </div>
                        <div class="form-group mb-3">
                          <input name="password"  v-model="password" class="form-control" :class="{ 'is-invalid': passwordError }" type="password" :placeholder="contents.header.login.placeHolder2"/>
                          <div v-if="passwordError" class="invalid-feedback">
                            {{ passwordError }}
                          </div>
                        </div>
                        <p class="small mt-3"><a :href="contents.header.login.forgotPasswordHref" target="_blank" rel="noreferrer">{{contents.header.login.forgotPasswordTxt}}</a></p>
                        <div class="form-group">
                          <button class="btn btn-secondary btn-block mb-2" type="submit">{{contents.header.login.submitBtn}}</button>
                        </div>
                        <p class="small mt-3"><a :href="contents.header.login.notRegisteredHref" target="_blank" rel="noreferrer" class="register">{{contents.header.login.notRegisteredTxt}}</a></p>
                        <div class="clear"></div>
                      </form>
                    </div>
                  </li>
                  <li class="nav-item" id="languageSelectMenu">
                    <div id="langDrop" class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle text-uppercase" href="#" id="navbarLang" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{userLanguage}}</a>
                      <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarLang">
                        <a class="dropdown-item" @click="changeLanguage('hu')">{{contents.header.languageSelect1}}</a>
                        <a class="dropdown-item" @click="changeLanguage('en')">{{contents.header.languageSelect2}}</a>
                      </div>
                    </div>
                  </li>
                </ul>
            </div>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#headerNavbar" aria-controls="headerNavbar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </nav>
</header>
</template>

<script>
import {Response} from '@/classes/response.js'
import {Validate} from '@/classes/validate.js'

export default{
  name:'Header',
  props:['globalData'],
  data(){
    return{
      email : null,
      emailError : null,
      password : null,
      passwordError : null
    }
  },
  computed: {
    userLanguage() {
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      return this.globalData.contents[language][247]
    }
  },
  methods:{
    loginHandler(){
      let validate = new Validate()

      this.emailError = validate.email(this.email, this.contents.header.login.error_1)
      this.passwordError = validate.password(this.password, this.contents.header.login.error_2)

      if (this.emailError || this.passwordError) {
        return false;
      }

      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('password', this.password)

      this.globalData.loader.visible = true
      this.$axios.post('v1/user/auth', formData)
          .then((response)=>{
            let responseData=new Response().handle(response);
            this.globalData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true){
              this.$router.push({name: 'Page_283_'+this.userLanguage})
            }
          })
          .catch(error =>{
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.globalData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.visible = false
          });
    },
    changeLanguage(language){
      let formData = new FormData()
      formData.append('language', language)

      this.globalData.loader.visible = true;
      this.$axios.post('v1/user/language',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.alerts.push({msg: responseData.msg, type: responseData.msgType})
            if(responseData.status===true) {
              this.globalData.init.language = language
            }

          })
          .catch(error => {
            console.log(error.message)
            console.error('There was an error!', error)
          })
          .then(() => {
            this.globalData.loader.visible = false;
          });
    }
  }
}
</script>

<style scoped>
header .navbar{
  padding: 0;
}
header .container{
  padding: 1.33rem;
}
.navbar-brand img{
  min-width: 222px;
  min-height: 25px;
}
#langDrop > a::before{
  position: relative;
  display: inline-block;
  content: "";
  margin-right: 5px;
  top: 2px;
  width: 1.1rem;
  height: 1.1rem;
  background: url('@/assets/img/globe.svg') no-repeat 0 0;
  background-size: 100%;
}
header .btn-registration{
  margin-left: 1.5rem;
}
.navbar-light .navbar-nav .nav-link{
  color: #000000;
  padding: 0 1rem;
}
.nav-item a{
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
}
.nav-item a.btn{
  font-weight: 700;
  font-size: inherit;
}
.dropdown-menu{
  min-width: 6rem;
}
.dropdown-menu-right {
  position: absolute;
  right: 0;
  left: auto;
  margin-top: 16px;
}
.dropdown-item{
  cursor: pointer;
  text-transform: uppercase;
}
.navbar-toggler{
  position: absolute;
  top: 1.33rem;
  right: 1.33rem;
}
.login-form{
  min-width: 300px;
  padding: 2rem;
}
.login-form p a{
  font-size: 0.875rem!important;
}
</style>