import { createRouter, createWebHistory } from 'vue-router'

const routeData = serverData.init.routes
const routes = []
for(let i=0;i<routeData.length;i++){
  routes.push(
      {
        path: routeData[i]['path'],
        name: routeData[i]['name'],
        component: () => import('../views/'+routeData[i]['component']+'.vue'),
        props: routeData[i]['props']
      }
  )
}

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        let dropdown = document.querySelector("#headerNavbar")
        if(dropdown){
            dropdown.classList.remove("show")
        }
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    history: createWebHistory('/'),
    routes
})

export default router
